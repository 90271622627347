<template>
    <div>
        <div v-if="data.length > 0">

            <!-- @TODO - make it general not just given collectionName -->
            <template v-if="['dht22', 'dht22_plus', 'weather_station'].includes($route.meta.collectionName)">
                <line-chart :data="data" :chart-data="[data.map(a => a.temperature),data.map(a => a.humidity),data.map(a => a.heat_index),data.map(a => a.dew_point),data.map(a => a.absolute_humidity)]" :chart-labels="data.map(a => a.datetime)"></line-chart>
            </template>



            <!-- @TODO - Pagination changes url not for modal-->
            <div class="container" v-if="this.collectionTotalCount > this.perPage">
                <div class="row justify-content-center">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="collectionTotalCount"
                        :per-page="perPage"
                        v-on:change="onChange"
                    ></b-pagination>
                </div>
            </div>


<!--            <line-chart :chart-data="[1,0.5,10]" :chart-labels="['bub','ak','ser']"></line-chart>-->
            <!-- @TODO - sticky-header max-height to fill window-->
            <!-- @TODO - make it sense ? sticky-header="85vh"-->
            <b-table
                     striped hover dark borderless small foot-clone :items="data" :busy="!isApiCallReady"
                     :fields="fields"
                     sort-icon-left
                     :sort-by.sync="sortBy"
                     :sort-desc.sync="sortDesc"
                     :selectable="asModal"
                     select-mode="single"
                     ref="selectableTable"
                     @row-selected="onRowSelected">
                <template #table-busy>
                    <div class="text-center text-light my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>

                <template v-if="asModal" #cell(#selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                        <span aria-hidden="true">&check;</span>
                        <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">Not selected</span>
                    </template>
                </template>

                <template v-if="!asModal" #cell(#actions)="entity">
                    <router-link v-if="typeof entity.item.id !== 'undefined'" :to="$route.path + '/' + entity.item.id" class="btn btn-secondary btn-sm" :title="$route.path + '/' + entity.item.id">
                        /{{ entity.item.id }}
                    </router-link>
                    <router-link v-if="typeof entity.item.id !== 'undefined'" :to="$route.path + '/' + entity.item.id + '/' + $restator.CONSTS.ACTIONS.EDIT" class="btn btn-secondary btn-sm" :title="$route.path + '/' + entity.item.id + '/' + $restator.CONSTS.ACTIONS.EDIT">
                        /{{ $restator.CONSTS.ACTIONS.EDIT }}
                    </router-link>
                    <!-- @TODO - add modal like "Are you sure?" + modal should be in same way like on RDocumentForm -->
                    <button class="btn btn-secondary btn-sm" @click="onDelete(entity.item.id)">
                        /{{ $restator.CONSTS.ACTIONS.DELETE }}
                    </button>
                </template>

                <template #head()="data">
                    <template  v-if="data.field.key[0] !== '#'">
                        {{ data.label }}
                        <!--  // @TODO - use constant instead of string -->
                        <b-icon v-if="$restator.store.appEnv !== 'prod'" :id="'popover-' + data.field.key" icon="info-circle"></b-icon>
                        <!--  // @TODO - use constant instead of string -->
                        <b-popover v-if="$restator.store.appEnv !== 'prod'" :target="'popover-' + data.field.key" triggers="hover" placement="top" variant="dark">
                            <template #title>Field Description</template>
                            <pre>{{ formatFieldDataTypeDescription(data.field.key) }}</pre>
                        </b-popover>
                    </template>
                </template>

                <template #foot()="data">
                    <i>{{ data.label }}</i>
                </template>

                <template #cell()="entity"><!-- @TODO - compare with dataType PRIMARY-->
                    <span v-if="entity.field.key === 'id'"><!-- @TODO - compare with dataType PRIMARY-->
                        {{ entity.value }}
                    </span>

                    <!-- @TODO - give it a try - to do it here instead of outside in RResource.vue -->
                    <!-- <span v-else-if="isForeignField(entity.field.key)"> </span>-->

                    <span v-else-if="typeof entity.value === 'object'">
                        <router-link :to="entity.value.routePath" title="View">
                            <b-icon icon="eye-fill"></b-icon>
                        </router-link>
                        <router-link :to="entity.value.routePath + '/' + $restator.CONSTS.ACTIONS.EDIT" title="Edit">
                            <b-icon icon="pencil-square"></b-icon>
                        </router-link>
                        {{ entity.value.newValue }}
                    </span>
                    <span v-else-if="type.properties[entity.field.key].type === 'boolean'">
                        {{ $restator.evaluateBoolean(entity.value) }}
                    </span>
                    <span v-else>
                        {{ entity.value }}
                    </span>
                </template>
            </b-table>
        </div>


        <div v-else>
            Collection <b>{{ $route.path }}</b> has nothing to show :/, but you can
            <router-link :to="$route.path + '/' + $restator.CONSTS.ACTIONS.ADD" :title="$route.path + '/' + $restator.CONSTS.ACTIONS.ADD">create one</router-link> ;)
        </div>
    </div>
</template>

<script>
    import LineChart from "./LineChart.vue"
    export default {
        name: "RCollection.vue",
        components: {
            LineChart
        },
        props: {
            asModal: {
                type: Boolean,
                default: false,
            },
            resourcePath: String,
        },
        data() {
            return {
                isApiCallReady: false,
                collectionTotalCount: 0,
                data: [],
                sortBy: 'id', // @TODO - compare with dataType PRIMARY
                sortDesc: false,
                fields: [],
                type: null,

                perPage: 20,
                numberOfPages: 1,
                currentPage: 1,
            }
        },
        computed: {
            modalSelectedIds:{
                get() { return this.$store.state.modalSelectedIds; },
                set(value) { this.$store.commit('setModalSelectedIds', value) }
            },
        },
        created() {
            this.initFields();
        },
        watch:{
            resourcePath () {
                this.initFields();
            },
        },
        methods: {
            formatFieldDataTypeDescription(field) {
                return JSON.stringify(this.type.properties[field], null, "  ");
            },
            formatter(value, field) {
                if (this.type.properties[field].maxLength > 255 && value.length > 100) {
                    return value.substr(0, 100) + '...';
                }
                return value;
            },
            onChange(page) {
                this.$router.push({ query: { page: page, _start: (page - 1) * this.perPage, _length: this.perPage } }); // @TODO - try to avoid refreshing same component when navigating between pages so it haven't to be in url // @TODO - _length should be optional and selectable by user
                this.initFields();
            },
            onRowSelected(items) {
                this.modalSelectedIds = [...new Set(items.map(item => item['id']))] // @TODO - PRIMARY
            },
            initFields() { // @TODO - rename function to init
                let endpoint = this.$route.meta;
                if (this.asModal) {
                  endpoint = this.$router.match(this.resourcePath).meta;
                }
                let dataTypeName = this.$restator.getDataTypeName(endpoint, 200); // @TODO - 200 based on response and also dataTypeName
                this.type = this.$restator.getType(dataTypeName);
                if (endpoint.action === this.$restator.CONSTS.ACTIONS.SHOW) {
                    this.isApiCallReady = false;
                    let params = {};
                    if (typeof this.$route.query._start !== 'undefined' && typeof this.$route.query._length !== 'undefined') {
                        params = {
                            _start: this.$route.query._start,
                            _length: this.$route.query._length,
                        };
                    }

                    this.$restator.getCollection(endpoint.collectionName, params)
                        .then((res) => {
                            this.data = res.data;
                            if (typeof this.data[0] !== 'undefined') {
                                if (this.asModal) {
                                    this.fields = [
                                        {key: '#selected', label: 'Selections', sortable: false},
                                    ];
                                } else {
                                    this.fields = [
                                        {key: '#actions', label: 'Actions', sortable: false},
                                    ];
                                }
                                for (const field of Object.keys(this.data[0])) {
                                    this.fields.push({
                                        key: field,
                                        label: this.getLabelFromField(field),
                                        sortable: true,
                                        formatter: 'formatter'
                                    });
                                }
                            }
                            this.collectionTotalCount = parseInt(res.headers['x-total-count'], 10);
                            this.isApiCallReady = true;
                        })
                        .then(() => {
                            this.numberOfPages = Math.ceil(this.collectionTotalCount / this.perPage);
                            this.currentPage = this.$route.query.page;
                            if (this.asModal) {
                                for (let selectedId of Object.values(this.modalSelectedIds)) {
                                    for (let [index, item] of Object.entries(this.data)) { // @TODO - bude chtit 2 apiCally (1) - na vylistovani zaznamu s aktualne vybranymi ID (2) - na vylistovani tech, co se budou zobrazovat v nabidce
                                        if (item.id === selectedId) { // @TODO - id/Primary
                                            this.$refs.selectableTable.selectRow(parseInt(index, 10));
                                        }
                                    }
                                }
                            }
                            let foreignResources = this.$restator.collectForeignResources(endpoint, this.data);
                            for (const foreignResource of Object.values(foreignResources)) {
                                for (const entryToFetch of Object.values(foreignResource.entriesToFetch)) {
                                    let subEndpointPath = '/' + foreignResource.foreignTable + '/' + entryToFetch;
                                    this.$restator.getDocument(foreignResource.foreignTable, entryToFetch)
                                        .then((res) => {
                                            this.refreshResourceKey += 1;
                                            const regex = /\/([^\s/]+)\/[^\s/]+/;
                                            const substitution = "$1";

                                            for (let index of Object.keys(this.data)) {
                                                for (const [localColumn, foreignResource] of Object.entries(foreignResources)) {
                                                    let resourceName = subEndpointPath.replace(regex, substitution);
                                                    if (foreignResource.foreignTable === resourceName) {
                                                        if (this.data[index][localColumn] === res.data.id) {
                                                            this.data[index][localColumn] = {
                                                                routePath: "/" + resourceName + "/" + res.data.id,// @TODO - not fixed "id"
                                                                // @TODO - maybe it's needed // collectionPath: res.collectionPath,
                                                                originalValue: this.data[index][localColumn],
                                                                newValue: this.data[index][localColumn] + ' (' + res.data[foreignResource.foreignColumnShow] + ')'
                                                            };
                                                        }
                                                    }
                                                }
                                            }
                                        })
                                }
                            }
                        })
                        .catch(error => {
                            this.$root.$emit('showToast', error.title, error.message, error.style); // @TODO - error.style should be selected here not in toast builder (simulation: replace "res.data" above with "res.res.data")
                        });
                }

            },
            isFieldToForeignTable(field) {
                return typeof this.dataType.properties[field]['x-foreignTable'] !== 'undefined';
            },
            getResourcePath(resourceName, resourceId = null) {
                let resourcePath = "/" + resourceName;
                if (resourceId) {
                    resourcePath += "/" + resourceId;
                }
                return resourcePath;
            },
            getResourceData(resourceName, resourceId = null) {
                return this.$restator.store.resources[this.getResourcePath(resourceName, resourceId)];
            },
            getForeignTableColumnValue(field, resourceId) {
                let resourceName = this.dataType.properties[field]['x-foreignTable'];
                let resourceField = this.dataType.properties[field]['x-foreignColumnShow'];
                return this.getResourceData(resourceName, resourceId)[resourceField];
            },
            getLabelFromField(field) { // @TODO - move to restator
                let label = field.charAt(0).toUpperCase() + field.slice(1);
                label = label.replace(/[^a-zA-z0-9]|[_]/g,' ');

                let dataTypeName = null;
                if (this.$route.path !== this.resourcePath) {
                    // FOR MODAL COLLECTION
                    dataTypeName = this.$restator.getDataTypeName(this.$router.match(this.resourcePath).meta, 200); // @TODO - 200 based on response and also dataTypeName
                } else {
                    dataTypeName = this.$restator.getDataTypeName(this.$route.meta, 200); // @TODO - 200 based on response and also dataTypeName
                }

                let dataType = this.$restator.getType(dataTypeName);
                if (typeof dataType.properties[field]['x-foreignColumnShow'] !== 'undefined') {
                    return label + ' (' + dataType.properties[field]['x-foreignColumnShow'] + ')';
                }
                return label;
            },
            getForeignTablePath(field, resourceId) {
                let resourceName = this.dataType.properties[field]['x-foreignTable'];
                return this.getResourcePath(resourceName, resourceId);
            },
            onDelete(id) {
                let endpoint = this.$router.match(this.$route.path + '/' + id + '/' + this.$restator.CONSTS.ACTIONS.DELETE).meta;
                let endpointPath = '/' + endpoint.collectionName + '/' + id;
                // @TODO - add more readable item (like title, name) to the deletion confirm
                this.$bvModal.msgBoxConfirm('Are you sure you want to permanently remove item "' + endpointPath + '"?', {
                    title: 'Confirm deletion',
                    okVariant: 'danger',
                    okTitle: 'Delete',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(confirmed => {
                    if (confirmed) {
                        this.$restator.deleteDocument(endpoint.collectionName, id)
                            .then((res) => {
                                this.$root.$emit('showToast', res.title, res.message, res.style);
                                this.data = this.data.filter(function (obj) {
                                    return obj.id !== id;
                                });
                            })
                            .catch(error => {
                                this.$root.$emit('showToast', error.title, error.message, error.style);
                            });
                    }
                })
                .catch(error => {
                    this.$root.$emit('showToast', 'Unexpected error during item "' + endpointPath + '" deletion!', error.message, 'danger');
                })
            },
        },
    }
</script>

<style scoped>
/deep/ thead tr th:first-child,
/deep/ tbody tr td:first-child {
    width: 12em;
    min-width: 12em;
    max-width: 12em;
    word-break: break-all;
}

@media (max-width: 800px) {
    /deep/ thead tr th:first-child,
    /deep/ tbody tr td:first-child {
        width: 5em;
        min-width: 5em;
        max-width: 5em;
        word-break: break-all;
    }
    /deep/ tbody tr td:first-child a {
        margin-top: 4px;
    }
}

.btn {
    margin-right: 0.25rem;
}

.b-icon.bi {
    margin: 0 3px;
    vertical-align: middle;
}

/deep/.pagination .page-item .page-link {
    color: white !important;
}

/deep/.pagination .page-item .page-link {
    background-color: #6c757d !important; /* BS secondary */
    border-color: #555 !important; /* BS secondary */
}

/deep/.pagination .page-item .page-link:hover {
    background-color: #5a6268 !important; /* BS secondary:hov */
    border-color: #555 !important; /* BS secondary:hov */
}

/deep/.pagination .page-item.disabled {
    cursor: not-allowed;
}

/deep/.pagination .page-item.disabled .page-link {
    background-color: #343a40 !important; /* BS dark */
    border-color: #555 !important; /* BS dark */
}

/deep/.pagination .page-item.disabled .page-link:hover {
    background-color: #23272b !important; /* BS dark:hov */
    border-color: #555 !important; /* BS dark:hov */
}

/deep/.pagination .page-item.active .page-link {
    background-color: #007bff !important; /* BS primary */
    border-color: #555 !important; /* BS primary */
}

/deep/.pagination .page-item.active .page-link:hover {
    cursor: not-allowed;
}
</style>